.footer-box {
    padding: 40px 30px;
    background: #BB2423;
    margin-top: 2.5vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.footer-link {
    color: #fff;
}

.footer-link .MuiSvgIcon-root:hover {
    color: aqua;
    transition: 200ms ease-in;
}

.footer-heading {
    font-size: 24px;
    color: #fff;
    margin-bottom: 40px;
    font-weight: bold;
}

@media (max-width: 1000px) {
    .footer-box {
        padding: 20px 10px;
    }

    .footer-container {
        max-width: 80vw;
    }
}