.subheader-heading {
    color: #FFF;
    text-align: center;
    margin-left: 2vw;
    font-size: clamp(3.5vw, 2.75rem, 3.5rem);
    margin: 0 2vw;
    max-width: 90vw;
}

.subheader-heading-2 {
    color: #FFF; 
    text-align: center; 
    font-size: clamp(2vw, 1.75rem, 3.5rem);
    margin: 1px 2.5vw;
    max-width: 90vw;
}

.subheader-heading-description {
    text-align: left; 
    font-size: clamp(1.10rem, 0.75rem, 0.55rem); 
    margin: 0.5vh 1px;
    max-width: 85vw;
    color: #fff;
}

.read-more {
    text-align: left; 
    font-size: 1.05rem; 
    margin: 0.75vh 1px; 
    color: #ffffff; 
    font-weight: 700;
}

.subheader-container {
    /* width: 2000px; */
    width: 100vw;
    height: 300px;
    /* margin: 0 auto; */
    padding: 2.5px;
    /* position: relative; */
    /* top: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-shadow: 0.35px 0.35px #fff;
    background-color: #a8201f;
    background-image: url('./../../../src/assets/Bacteria-red.jpg');
    background-size: 100vw 305px; 
}

.subheader-inner-container {
    display: flex;
}

.more-horiz-icon {
    display: none;
}

@media only screen and (max-width: 768px) {
    .subheader-container {
        width: 100%;
        margin: 0;
    }

    .subheader-inner-container {
        flex-direction: column;
    }

    .subheader-heading {
        min-width: 95vw;
        font-size: 2.5rem;
    }

    .subheader-heading-2 {
        max-width: 95vw;
        font-size: 1.55rem;
        margin: 8px;
    }

    .subheader-heading-description {
        font-size: 1.15rem;
        max-width: 95vw;
        margin: 0 8px;
    }

    .read-more {
        text-align: left; 
        font-size: 1.15rem; 
        margin: 0 8px; 
        color: #ffffff; 
        font-weight: 600;
    }

    .more-horiz-icon {
        display: block;
        margin: auto;
    }

    .mobile-hidden {
        display: none;
    }
}