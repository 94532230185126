.topic-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10vw;
}

.topic-links {
    max-width: 45vw;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.img-topic {
    width: 40vw;
    height: 50vh;
}

.image-licence {
    width: 40vw;
}

.topic-paragraph {
    font-size: 1.25rem;
    font-weight: 600;
    max-width: 40vw;
}

@media only screen and (max-width: 768px) {
    .topic-container {
        flex-direction: column;
        /* justify-content: flex-start; */
        /* gap: 10vw; */
    }

    .topic-links {
        max-width: 90vw;
    }

    .topic-paragraph {
        font-size: 1rem;
        font-weight: 500;
        max-width: 90vw;
    }

    .img-topic {
        width: 90vw;
        height: 50vh;
    }

    .image-licence {
        width: 90vw;
    }
}