/* .search-container {
    min-width: 40vw;
    display: flex;
} */

.container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 5.05rem auto;
}

.select-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    gap: 7.5px;
    min-width: 45vw;
    margin-top: 10px;
}

.search-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    min-width: 45vw;
} 

.featured-articles-container {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    /* gap: 2.5vw; */
    padding: 1%;
}

.featured-info-container {
    display: flex;
    gap: 1.5vw;
    flex-direction: column;
    padding: 2.5px 10px;
    /* align-self: center; */
}

.featured-container {
    display: flex;
    /* align-self: center; */
    flex-direction: column;
    border: 1px solid #C13938;
    border-radius: 7.5px;
    width: 30vw;
}

.featured-description {
    width: 28vw;
    margin: 0;
}

.image {
    /* width: 466px; 
    height: 311px; */
    width:28.6vw;
    height: 34vh;
}

.discover-more-btn {
    /* width: 30vw !important; */
    width: 28.6vw;
    display: flex;
    justify-content: center;
    padding: 2.5px 10px;
    margin-bottom: 2px;
}

.featured-category-articles {
    text-align: left;
    color: #000;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 28.6vw;
    padding: 2.5px 10px;
}

.single-featured-articles-container {
    background: #fff;         
    border: 1px solid #C13938;
    /* color: #C13938;  */
    width: 22%; 
    /* border: 1px solid #C13938;  */
    padding: 12.5px; 
    border-radius: 7.5px; 
    /* min-width: 44vh; */
    min-width: 30vw;
    height: fit-content;
}

.featured-inner-container {
    /* max-width: 35vw; */
    width: 28.6vw;
}

li {
    color: #C13938;
}

li:hover {
    background-color: #C13938 !important;
    color: #f6f5f5 !important;
}

.change-text-style-on-hover:hover {
    color: #C13938 !important;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .search-btn-container {
        margin-left: 2.5px;
        margin-right: 2.5px;
        min-width: 75vw;
    }

    .search-container {
        width: 70vw;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    .select-container {
        display: flex;
        flex-direction: row;
        justify-content: baseline;
        align-items: baseline;
        margin-top: 1.5vh;
        max-width: 95vw;
    }

    .featured-category-articles {
        width: 95vw;
    }

    .featured-articles-container {
        flex-direction: column;
        gap: 5px;
    }

    .single-featured-articles-container {
        align-self: center;
        min-width: 90vw;
    }

    .image {
        /* width: 333px;
        height: 200px; */
        width: 55vw;
        height: 30vh;
        
    }

    .image-container {
        display: flex;
        margin: 0 auto;
    }

    .featured-info-container {
        flex-direction: column;
        margin: 0 auto;
    }

    .featured-inner-container {
        max-width: 90vw;
    }

    .featured-description {
        width: 90vw;
    }

    .discover-more-btn {
        width: 90vw !important;
        display: flex;
        justify-content: center;
    }

    .featured-container {
        width: 95vw;
    }
}
