.external-source {
    /* width: 75%; */
    /* width: 100rem; */
    /* height: 50rem; */
    border: 2.5px solid #C13938;
    border-radius: 7.5p;
    height: 80vh;
    width: 45vw;
}

.synopsis-paragraph {
    color: #C13938;
    text-align: left;
    width: 45vw;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 1px;
}

.document-container {
    display: flex;
    margin: 0 5.5vw;
    justify-content: space-between;
    gap: 5vw;
    align-items: baseline;
}

.document-links {
    text-align: left;
    max-width: 30vw;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis
}

.td-style {
    border: 1px solid #fff; 
    text-align: left; 
    padding: 8px; 
    color: #000; 
    font-size: 1.15rem; 
    font-weight: 500;
}

.error-img {
    width: 45vw;
    height: 30vw;
}

@media only screen and (max-width: 768px) {
    .document-container {
        flex-direction: column;
        margin: 0 1vw;
    }

    .external-source {
        width: 90vw;
    }
    .error-img {
        width: 90vw;
        height: 35vh;
    }
    .synopsis-paragraph {
        width: 90vw;
        font-size: 1rem;
        font-weight: 500;
    }

    .document-links {
        max-width: 90vw;
    }

    td {
        font-size: 0.85rem !important;
    }
}