.metadata-container {
    display: flex;
    justify-content: space-around;
}

.join-container {
    display: flex; 
    flex-direction: column; 
    color: #BB2423; 
    justify-content: center;
}

.how-to-use-container {
    display: flex; 
    flex-direction: column; 
    color: #BB2423; 
    justify-content: center;
}

.how-to-use-wrapper {
    display: flex;
    justify-content: center;
}

.icon-container-mobile {
    display: none;
}

.metadata-description{
    color: #BB2423; 
    width: 82vw;
    text-align: left;
    margin: 0;
}

@media only screen and (max-width: 768px) {
    .info-texts-mobile, .info-texts-mobile-heading {
        width: 95vw !important;
    }

    .how-to-use-container {
        justify-content: flex-start;
    }

    .metadata-container {
        justify-content: flex-start;
        flex-direction: column;
    }

    .icon-container {
        display: none;
    }

    .icon-container-mobile {
        display: flex;
        align-self: center;
    }

    .statement-link-btn {
        margin: 0 auto !important;
    }
}