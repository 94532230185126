.main-container {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
}

.lower-tabs-container {
    display: flex; 
    align-items: center; 
    gap: 1vw; 
}

.lang-data-grid-container {
    height: 41rem; 
    width: 68rem;
}

.full-repo-data-grid-container {
    height: 41rem; 
    width: 95.5rem;
}

.doc-type-data-grid-container {
    height: 41rem; 
    width: 71rem;
    margin: auto;
}

.disease-specific-data-grid-container {
    height: 41rem;
    width: 80.5rem;
}

@media only screen and (max-width: 768px){
    .lower-tabs-container {
        flex-direction: column;
        gap: 0;
    }

    .lower-tabs-container > h3 {
        margin: 0 !important;
        margin-top: 7.5px !important;
        font-size: small;
    }

    .MuiTabs-flexContainer {
        flex-direction: column;
    }

    .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        width: 44vw;
    }
}

@media only screen and (max-width: 1100px) {
    .lang-data-grid-container {
        width: 95vw;
        height: 80vh;
    }

    .full-repo-data-grid-container {
        width: 95vw;
        height: 80vh;
    }

    .doc-type-data-grid-container {
        width: 95vw;
        height: 80vh;
    }

    .disease-specific-data-grid-container {
        width: 95vw;
        height: 80vh;
    }
    
}