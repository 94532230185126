
.super-app-theme--header {
  background-color: #C13938;
  color: #f6f5f5;
  font-weight: bold;
}

.super-app-theme--cell {
  background-color: #f6f5f5;
  color: #C13938;
}


body {
  background-color: #ffffff;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.content {
  flex: 1;
}

.footer {
  flex-shrink: 0;
}


/* @media only screen and (max-width: 600px) {
  .app-wrapper {
    overflow-x: hidden;
  }
} */